<template>
    <div id="data-list-list-view" class="data-list-container">
      <h1>{{ $t("enters") }}</h1>
      <br>
   
      <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="enters">
  
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
  
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <!-- ADD NEW -->
            
          </div>
  
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ enters.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : enters.length }} of {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
  
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=40">
                <span>40</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=60">
                <span>60</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=80">
                <span>80</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
  
        <template slot="thead">
          <vs-th sort-key="Auteur">{{ $t("Auteur") }}</vs-th>
          <vs-th sort-key="enters">{{ $t("produits") }}</vs-th>
          <vs-th sort-key="stockTypes">{{ $t("stockTypes") }}</vs-th>
          <vs-th sort-key="quantity">{{ $t("quantity") }}s</vs-th>
          <vs-th sort-key="addDate">{{ $t("Date d'approvision") }}</vs-th>
          <vs-th sort-key="UpDate">{{ $t("Date d'entrée") }}</vs-th>

          <vs-th>Action</vs-th>
        </template>
  
        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.author.last_name}}{{" "}}{{tr.author.first_name}}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.stock.name}}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.stock.stockType.name}}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.quantity}}</p>
            </vs-td>
            <vs-td>
              <vs-chip color="warning">
                <vs-avatar icon-pack="feather" icon="icon-corner-down-left" />
                <p class="product-name font-medium truncate">{{tr.stock.created_at | date_time}}</p>
                </vs-chip>
            </vs-td>
            <vs-td>
              <vs-chip color="success">
                <vs-avatar icon-pack="feather" icon="icon-corner-up-right" />
                <p class="product-name font-medium truncate">{{tr.updated_at | date_time}}</p>
                </vs-chip>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <div class="flex">
                <vx-tooltip text="Voir" color="primary">
                 <feather-icon 
                     style="color: navy " 
                     icon="EyeIcon" 
                     svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      @click="activePrompt5 = true"
                      v-on:click="openPlayerDialog(tr)" 
                      color="warring" type="border"
                      class="ml-2" />
                  </vx-tooltip>
              </div>
            </vs-td>
          </vs-tr>
          </tbody>
        </template>
      </vs-table>
     
    <vs-popup title="DÉTAILS "  :active.sync="activePrompt5">
      <div class="vx-row" v-if="selectedPlayer">
        <div class="vx-col sm:w-full  xs:w-full">
          <vs-input readonly :label="this.$t('stock') "  v-model=" selectedPlayer.stock.name" class="mt-4 mb-2 w-full" />
          <vs-input readonly :label="this.$t('quantity') "  v-model="selectedPlayer.quantity" class="mt-4 mb-2 w-full" />
          <vs-input readonly :label="this.$t('stockType') "  v-model=" selectedPlayer.stock.stockType.name " class="mt-4 mb-2 w-full" />
          
          <vs-input v-if="selectedPlayer.stock.stockType.name ==='Produit'" readonly :label="this.$t('dosage') "  v-model="selectedPlayer.stock.dosage" class="mt-4 mb-2 w-full" />
          <vs-input v-if="selectedPlayer.stock.stockType.name ==='Produit'"  readonly :label="this.$t('indication')"  v-model=" selectedPlayer.stock.indication" class="mt-4 mb-2 w-full" />
          <vs-input v-if="selectedPlayer.stock.stockType.name ==='Produit'" readonly :label="this.$t('routeOfAdministration')"  v-model=" selectedPlayer.stock.routeOfAdministration" class="mt-4 mb-2 w-full" />
          <vs-input v-if="selectedPlayer.stock.stockType.name ==='Produit'" readonly :label="this.$t('contraindication')"  v-model=" selectedPlayer.stock.contraindication" class="mt-4 mb-2 w-full" />
          <vs-input v-if="selectedPlayer.stock.stockType.name ==='Produit'" readonly :label="this.$t('sideEffects')"  v-model=" selectedPlayer.stock.sideEffects" class="mt-4 mb-2 w-full" />
          
          <small class="date-label">{{ $t("Date d'approvision") }}</small>
          <flat-pickr disabled :config="configdateTimePicker"  v-model="selectedPlayer.stock.created_at" class=" mb-2 w-full" />
          <small class="date-label">{{ $t("Date d'entrée") }}</small>
          <flat-pickr disabled :config="configdateTimePicker" v-model="selectedPlayer.updated_at" class=" mb-2 w-full" />
        
        </div>
      </div>
    </vs-popup>

    </div>
    
  </template>
<script>
  
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.min.css'
import {French as FrenchLocale} from 'flatpickr/dist/l10n/fr.js'

export default {
  components: {
    flatPickr
  },
  data () {
    return {
      
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: FrenchLocale
      },
      activePrompt5:false,
      enters: [],
      selected: [],
      itemsPerPage: 20,
      isMounted: false,
      addNewDataSidebar: false,
      sidebarData: {}
    }
  },
  
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.enters.length
    }
  
  },
  methods: {
    
    openPlayerDialog (player) {
      this.selectedPlayer = player
    },
  
    getAllstocks () {
      this.$vs.loading()
      this.$http.get('enters/')
        .then((response) => {
          this.enters = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }

  },
  async created () {
    window.enters = this
    this.getAllstocks()
    document.title = 'MooziMed'


  },
  mounted () {
    this.isMounted = true
  }
}
</script>
  
  <style lang="scss">
  #data-list-list-view {
    .vs-con-table {
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;
  
          .vs-table--search-input {
            width: 100%;
          }
        }
      }
  
      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }
  
      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;
  
          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }
  
      .product-name {
        max-width: 23rem;
      }
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search{
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            &+i {
              left: 1rem;
            }
  
            &:focus+i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
        }
      }
  
      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
  
  <style scoped>
  
  </style>
  
  <style scoped>
  
  </style>
  